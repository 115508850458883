.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.radio-tile-group .input-container {
  position: relative;
  height: 4rem;
  width: 4rem;
  margin: 0.5em;
}
.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}
.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #4ebd05;
  border-radius: 5px;
  /* padding: 1rem;
     */
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}
.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #4ebd05;
}
.radio-tile-group .input-container .radio-button:checked + .radio-tile {
  background-color: #4ebd05;
  border: 2px solid #4ebd05;
  color: white;
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .radio-tile-label {
  color: white;
  background-color: #4ebd05;
}
#otheramount {
  font-weight: 600;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  border: 2px solid #4ebd05;
  border-radius: 5px;
  padding: 0;
  color: #4ebd05;
  font-size: 100%;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}
#otheramount.active {
  background-color: #4ebd05;
  border: 2px solid #4ebd05;
  color: white;
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.input-text {
  border: 1px solid #b1b1b1;
  padding: 1rem;
  font-size: 20px;
  color: #4ebd05;
  width: calc(100% - 1rem);
  margin: 0;
  padding: 0.5rem;
}

.input-text::placeholder {
  color: grey;
}

.input-button {
  width: 100%;
  border: 1px solid grey;
  border-radius: 0.3rem;
  font-size: 2rem;
}

.input-button:hover:enabled {
  background-color: #cccccc;
  border: 1px solid rgb(255, 255, 255);
  cursor: pointer;
}
